.timeline {
  margin: $spacer-3 0;
  padding: 0 $spacer-1;
}

.timeline-header {
  margin: 0;
  padding-left: $spacer-3;
  font-size: $body-font-size;
  color: $text-gray;
  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding-left: 44 + $spacer-3;
  }
}

.timeline-header em {
  font-weight: $font-weight-normal;
}

.page-loader {
  position: relative;
  margin: 40px 0;
  
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-50% - 8px));
    z-index: 1;
  }
}